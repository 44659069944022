@import "./common_import.scss"; // Common less

.section_body {
  width: 100%;
}

.dialog__paragraphs {
  p {
    font-size: 14px;
    line-height: 20px;
    padding-top: 10px;
    strong {
      font-size: 15px;
      font-weight: 600;
    }
  }
}

.company_list {
  margin-top: 20px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
    color: $theme-color-secondary;
  }
  .__c_list {
    display: flex;
    align-items: center;
    gap: 30px;
    div {
      flex: 1;
      max-width: 25%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}

#transition-modal-title {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 20px;
}

.MuiDialog-container {
  .MuiButton-root {
    .MuiButton-label {
      font-weight: 600;
    }
  }
  .MuiButton-containedPrimary {
    background: $theme-color-secondary;
    &:hover {
      background: $theme-color-secondary;
    }
  }
  .MuiTypography-h6 {
    font-size: 20px;
    font-weight: 600;
  }
  p {
    font-size: 14px;
    line-height: 20px;
  }
  ul {
    margin: 20px 0;
    li {
      margin-left: 20px;
      font-size: 14px;
      line-height: 20px;
      padding-top: 5px;
      list-style-type: disc;
    }
  }
}

.__menu_links {
  padding-top: 100px;
  .__close {
    top: 0;
    right: 10px;
    position: absolute;
    display: none;
    text-align: right;
    @include for-phone-only {
      display: block;
    }
  }
  .header__logo {
    img {
      max-width: 70%;
    }
  }
  a {
    padding: 30px;
    font-weight: 600;
    display: block;
    width: 100%;
    font-size: 24px;
    color: $theme-color-secondary;
    &:hover {
      color: $theme-color-primary;
    }
  }
}

.wrapper {
  @include for-phone-only {
    width: 100% !important;
  }
}

.page__section {
  &._top {
    height: 100vh;
    position: relative;
    background: url("#{$url-img}/bg.jpg");
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      content: "";
      height: 100%;
      position: absolute;
      background: rgba($theme-color-primary, 0.6);
    }
    .wrapper {
      z-index: 1;
      width: 70%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
    .section__body {
      text-align: center;
      h1 {
        font-size: 45px;
        font-weight: 600;
        color: $theme-color-white;
        font-family: $theme-heading-font;
        padding-bottom: 40px;
        @include for-phone-only {
          font-size: 30px;
          line-height: 40px;
        }
      }
      .__action {
        display: flex;
        margin-top: 40px;
        justify-content: center;
      }
      h2 {
        gap: 15px;
        display: flex;
        font-size: 25px;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        color: $theme-color-white;
        font-family: $theme-heading-font;

        @include for-phone-only {
          font-size: 20px;
          flex-direction: column;
        }
        .__dot {
          height: 20px;
          top: -12px;
          font-size: 90px;
          position: relative;
          line-height: 0;
          display: inline-block;
          vertical-align: inherit;
        }
        .__line {
          height: 3px;
          width: 100px;
          display: inline-block;
          background: $theme-color-secondary;
        }
      }
    }
  }

  &._services {
    top: -70px;
    padding: 0;
    position: relative;
    .wrapper {
      @include for-phone-only {
        padding: 20px;
        padding-bottom: 0;
      }
    }
    .section__body {
      gap: 20px;
      display: flex;
      flex-wrap: wrap;
      .service__card {
        flex: 1;
        gap: 25px;
        min-width: 40%;
        padding: 30px;
        display: flex;
        cursor: pointer;
        background: $theme-color-white;
        border-radius: 10px;
        box-shadow: 0 0 10px rgba($theme-color-black, 0.1);
        @include for-phone-only {
          min-width: auto;
        }
        &:hover {
          background: $theme-color-primary;
          .__icon {
            svg {
              fill: $theme-color-white;
            }
          }
          .__text {
            color: $theme-color-white;
          }
        }
        .__icon {
          min-width: 35px;
          width: 35px;
          height: 35px;
        }
        .__text {
          h3 {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 10px;
          }
          p {
            line-height: 18px;
          }
        }
      }
    }
  }

  &._big_team {
    .wrapper {
      width: 800px;
    }
    .section__body {
      display: flex;
      flex-direction: column;
      gap: 60px;
    }
    .big__team_card {
      display: flex;
      @include for-phone-only {
        flex-direction: column;
      }
      .team__img {
        flex: 1;
      }
      .team__detail {
        flex: 1;
        h3 {
          font-size: 22px;
          font-weight: 600;
          margin-bottom: 15px;
          font-family: $theme-heading-font;
        }
        p,
        .__about {
          line-height: 22px;
          margin-bottom: 15px;
        }
        .__action {
          h4 {
            font-size: 25px;
            margin-top: 25px;
            margin-bottom: 25px;
            font-family: $theme-sp-font;
          }
        }
      }
    }
  }

  &._practice_area {
    .section__body {
      //   display: flex;
      //   flex-wrap: wrap;
      display: grid;
      grid-template-columns: 20% 20% 20% 20% 20%;
      @include for-phone-only {
        grid-template-columns: 50% 50%;
      }
      .practice__card {
        padding: 20px;
        cursor: pointer;
        text-align: center;
        box-shadow: 0 0 6px -6px #000;
        .__icon {
          width: 42px;
          height: 42px;
          display: inline-block;
        }
        h3 {
          font-size: 14px;
          margin-top: 15px;
          line-height: 20px;
          margin-bottom: 10px;
        }
        .__arrow {
          @include opacity(0);
        }
        &:hover {
          color: $theme-color-white;
          border-radius: 10px;
          background: $theme-color-primary;
          box-shadow: 0 0 10px rgba($theme-color-primary, 0.6);
          .__icon {
            fill: $theme-color-white;
          }
          .__arrow {
            @include opacity(1);
          }
        }
      }
    }
  }

  &._testimonials {
    .testimonial__text {
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
    .slick-current {
      .testimonial__profile {
        @include opacity(1);
        transform: scale(1);
      }
    }
    .__quote {
      transform: rotate(180deg);
    }
    .testimonial__profile {
      @include opacity(0.5);
      transform: scale(0.9);
      text-align: center;
      cursor: pointer;
      margin-top: 20px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: inline-block;
      }
      .__detail {
        margin-top: 15px;
        h4 {
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          font-family: $theme-sp-font;
        }
        p {
          font-size: 12px;
          @include opacity(0.5);
        }
      }
    }
  }

  &._we_help {
    background-image: linear-gradient(
      109.6deg,
      $theme-color-primary 11.2%,
      rgb(93, 52, 236) 100.2%
    );
    .wrapper {
      display: flex;
      gap: 20px;

      @include for-phone-only {
        flex-direction: column;
      }
    }
    .section__head {
      text-align: left;
      margin-bottom: 0;
      flex: 0.8;
      @include for-phone-only {
        flex: 1;
      }
      h2 {
        color: $theme-color-white;
        &:after {
          display: none;
        }
      }
      p {
        color: $theme-color-white;
      }
    }
    .section__body {
      flex: 1;
      gap: 10px;
      display: flex;
      text-align: center;
      position: relative;
      &:after {
        content: "";
        position: absolute;
        height: 100%;
        width: 107%;
        left: -16px;
        top: -98px;
        border: 1px dashed rgba(255, 255, 255, 0.3);
      }
      div {
        gap: 10px;
        display: flex;
        z-index: 1;
        position: relative;
        flex-direction: column;
        &:last-child {
          padding-top: 40px;
        }
      }
      img {
        height: max-content;
        border-radius: 10px;
      }
    }
  }

  &._attorney_profile {
    .section__body {
      display: flex;
      gap: 40px;
      @include for-phone-only {
        flex-direction: column;
      }
    }
    .attorney_card {
      flex: 1;
      gap: 15px;
      display: flex;
      align-items: center;
      .__img {
        min-width: 120px;
        width: 120px;
        height: 150px;
        overflow: hidden;
        border-radius: 10px;
      }
      .__details {
        h3 {
          font-size: 20px;
          font-family: $theme-sp-font;
        }
        .LinesEllipsis {
          margin-top: 10px;
          margin-bottom: 10px;
          line-height: 20px;
        }
        .btn {
          padding: 0;
          min-width: auto;
          color: $theme-color-secondary;
        }
      }
    }
  }

  &._contact_us {
    background-image: linear-gradient(
      109.6deg,
      $theme-color-primary 11.2%,
      rgb(93, 52, 236) 100.2%
    );
    .wrapper {
      width: 600px;
    }
    .__note {
      @include opacity(0.5);
      text-align: center;
      margin-top: 30px;
      line-height: 20px;
      color: $theme-color-white;
    }
    .section__head {
      color: $theme-color-white;
      h2 {
        color: $theme-color-white;
      }
    }
    .contact__form {
      border-radius: 20px;
      background: $theme-color-white;
      input,
      textarea {
        border: 0;
        outline: 0;
        width: 100%;
        font-size: 16px;
        padding: 0 10px;
        font-weight: 600;
        font-family: $theme-font;
        &:focus {
          background: transparent;
        }
      }
      .form__row {
        display: flex;
        border-top: 1px solid #eee;
        @include for-phone-only {
          flex-direction: column;
        }
        &._action {
          border-top: 0;
          padding: 20px;
          justify-content: flex-end;
          .btn._prime {
            width: auto;
            padding: 17px;
          }
        }
        &:first-child {
          border-top: 0;

          @include for-phone-only {
            .form__input {
              &:last-child {
                border-top: 1px solid #eee;
              }
            }
          }
        }

        .form__input {
          flex: 1;
          padding: 28px;
          display: flex;
        }
      }
    }
  }

  &._about_top {
    padding: 30px;
    height: 100%;
    position: relative;
    background: url("#{$url-img}/bg.jpg");
    @include for-phone-only {
      padding-top: 100px;
    }
    &:after {
      top: 0;
      left: 0;
      width: 100%;
      content: "";
      height: 100%;
      position: absolute;
      background: rgba($theme-color-primary, 0.6);
    }
    .wrapper {
      z-index: 1;
      width: 60%;
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
      text-align: center;
      justify-content: center;
      h1 {
        font-size: 45px;
        font-weight: 600;
        color: $theme-color-white;
        font-family: $theme-heading-font;
        padding-bottom: 40px;
      }
    }
  }

  &._text {
    p {
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
