@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");

/*----------  Theme color variables  ----------*/

$theme-color-primary: #11284a;
$theme-color-secondary: #ab9765;
$theme-heading-color: #131e41;
$theme-text-color: #666;
$theme-svg-color: #cacad4;
$theme-color-white: #fff;
$theme-color-black: #000;

/*----------  Font variables  ----------*/
$theme-font: "Roboto", sans-serif;
$theme-heading-font: auto;
$theme-sp-font: "Pacifico", cursive;

// --------------------------------
// Content url:
// How to use: a { background:url('${url-cdn}/img.jpg')};
// --------------------------------
$url-img: "/assets/img/";
$url-font: "/styles/fonts/";
$url-svg: "/assets/svg/";

@mixin text-truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@mixin vertical-align($type) {
  display: inline-block;
  vertical-align: $type;
}

@mixin rgbaColor($color, $opacity: 1) {
  @result: rgba(red($color), green($color), blue($color), $opacity);
}

@mixin opacity($opacity: 0.5) {
  -webkit-opacity: $opacity;
  -moz-opacity: $opacity;
  opacity: $opacity;
}

@mixin for-phone-only {
  @media (min-width: 320px) and (max-width: 900px) {
    @content;
  }
}
