#root,
.App {
  height: 100%;
}

header {
  display: flex;
  padding: 20px;
  z-index: 1;
  position: relative;
  align-items: center;
  justify-content: space-between;
  &._bg_transparent {
    top: 0;
    left: 0;
    width: 100%;
    position: absolute;
    .header__logo {
      color: $theme-color-white;
    }
    .header__links {
      .header__logo {
        display: none;
      }
      a {
        color: $theme-color-white;
      }
    }
  }
  .header__logo {
    font-size: 20px;
    font-weight: 600;
    font-family: $theme-heading-font;
    img {
      max-width: 70%;
    }
  }
  .__hamb_menu {
    display: none;
    @include for-phone-only {
      display: block;
    }
  }
  .header__links {
    gap: 10px;
    display: flex;
    .__close {
      display: none;
    }
    @include for-phone-only {
      display: none;
    }
    a {
      padding: 10px;
      font-weight: 600;
      &:hover {
        color: $theme-color-secondary;
      }
    }
  }
}

.wrapper {
  width: 940px;
  margin: 0 auto;
}

.page__section {
  padding: 90px 15px;
  .wrapper {
    padding: 0;
    margin: 0 auto;
  }

  .section__head {
    width: 100%;
    margin: 0 0 40px;
    text-align: center;
    h2 {
      font-size: 30px;
      font-weight: 600;
      margin: 0 0 20px;
      line-height: 58px;
      font-family: auto;
      position: relative;
      color: $theme-heading-color;
      &:after {
        top: -10px;
        left: 50%;
        height: 4px;
        content: "";
        width: 100px;
        position: absolute;
        margin-left: -50px;
        background: $theme-color-secondary;
      }
    }
    p {
      font-size: 16px;
      line-height: 21px;
      font-weight: 400;
      margin: 0 0 20px;
      display: inline-block;
    }
  }
  .section__body {
    width: 100%;
  }
}

.contact__page {
  .page__section {
    padding-top: 140px;
  }
}
