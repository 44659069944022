footer {
  background-image: linear-gradient(
    109.6deg,
    $theme-color-primary 11.2%,
    rgb(93, 52, 236) 100.2%
  );
  .footer_row {
    display: flex;
    padding: 40px;
    gap: 40px;
    color: $theme-color-white;
    justify-content: space-between;
    @include for-phone-only {
      flex-direction: column;
      padding: 40px 20px;
    }

    &._copy_right {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 15px;
      .footer_col {
        text-align: center;
        @include opacity(0.5);
      }
    }
  }

  .__logo {
    font-size: 20px;
    font-weight: 600;
    color: $theme-color-white;
    font-family: $theme-heading-font;
    img {
      max-width: 70%;
    }
  }
  .__tel {
    color: $theme-color-white;
    display: flex;
    margin: 20px 0;
    align-items: center;
    gap: 10px;
  }
  .__about {
    margin-top: 10px;
    line-height: 18px;
  }
  address {
    font-style: normal;
    line-height: 22px;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .footer_col {
    flex: 1;
    &._about {
      min-width: 40%;
    }
    h2 {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .__links {
      a {
        width: 100%;
        padding: 7px 0;
        display: inline-block;
        color: $theme-color-white;
        &:hover {
          color: $theme-color-secondary;
        }
      }
    }
  }
}
