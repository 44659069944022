html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video,
textarea {
  margin: 0;
  border: 0;
  padding: 0;
  outline: 0;
  font-size: 13px;
  font-weight: 400;
  line-height: 100%;
  word-wrap: break-word;
  background: transparent;
  vertical-align: baseline;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

canvas {
  width: 100%;
}

img {
  height: auto;
  max-width: 100%;
  display: inline-block;
  vertical-align: middle;
}
body {
  line-height: 1;
  user-select: none;
  overflow-x: hidden;
}
body,
html {
  width: 100%;
  height: 100%;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ul {
  list-style: none;
}
a {
  margin: 0;
  padding: 0;
  cursor: pointer;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input,
select {
  vertical-align: middle;
}

svg {
  width: 100%;
  height: 100%;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
