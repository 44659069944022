@-webkit-keyframes animation-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes animation-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes animation-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes animation-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@mixin btnloading($color) {
  &:before {
    $size: 15px;
    $border-color: $color;
    $border-weight: 2px;
    $offset: ($size + $border-weight) * 0.5;

    margin: -$offset 0 0 -$offset;
    width: $size;
    height: $size;
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    content: "";
    border: fade($border-color, 25%) $border-weight solid;
    border-top-color: $border-color;
  }
}

.__not_clickable {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.btn {
  border: 0;
  padding: 17px;
  font-size: 14px;
  cursor: pointer;
  min-width: 150px;
  font-weight: 600;
  appearance: none;
  text-align: center;
  position: relative;
  border-radius: 5px;
  display: inline-block;
  letter-spacing: 0.5px;
  -moz-appearance: none;
  background: transparent;
  -webkit-appearance: none;
  text-transform: uppercase;
  -webkit-transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);
  transition: 0.2s all cubic-bezier(0.4, 0, 0.2, 1);

  &._pill {
    width: auto;
    min-width: auto;
    border-radius: 5px;
    font-weight: initial;
    padding: 0 20px;
    color: $theme-color-black;
    border: 1px solid #eeeff0;
  }

  .__lock {
    top: 50%;
    left: 10px;
    margin-top: -8px;
  }

  &._big {
    height: auto;
    font-size: 16px;
    padding: 20px;
    line-height: initial;
  }

  &:hover {
    background-position: right center;
  }

  &._loading {
    @include btnloading($theme-color-white);
    pointer-events: none;
  }

  &._bordered {
    background: transparent;
    color: $theme-color-primary;
    border: 1px solid $theme-color-primary;
    &._loading {
      @include btnloading($theme-color-primary);
      color: transparent !important;
    }
    &._1 {
      color: $theme-color-secondary;
      border: 1px solid $theme-color-secondary;
    }
    &:hover {
      background: $theme-color-primary;
      color: $theme-color-white;
    }
  }

  &._upload {
    background: transparent;
    color: $theme-color-primary;
    border: 1px solid $theme-color-primary;
    input {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      @include opacity(0);
    }
  }

  &._white {
    background: transparent;
    color: $theme-color-white !important;
    border: 1px solid $theme-color-white;
    &:hover {
      color: $theme-color-secondary !important;
      background: $theme-color-white;
    }
  }

  &._icon {
    width: 45px;
    padding: 9px;
    min-width: auto;
    fill: $theme-color-secondary;
    vertical-align: middle;
  }

  &._with_icon {
    .__icon {
      width: 20px;
      height: 20px;
      margin-right: 5px;
      display: inline-block;
      vertical-align: middle;
    }
  }

  &._filled,
  &.btn-primary {
    color: $theme-color-secondary;
    border: 1px solid $theme-color-primary;
    background: $theme-color-primary;
    &._loading {
      color: transparent !important;
    }
    &:hover {
      box-shadow: 0px 10px 20px rgbaColor($theme-color-primary, 0.2);
    }
  }

  &._white {
    color: $theme-color-primary;
    &._loading {
      color: transparent;
    }
  }

  &._prime {
    color: $theme-color-white;
    border: 1px solid $theme-color-secondary;
    background: $theme-color-secondary;
    &:focus {
      background: $theme-color-secondary !important;
    }
    &._loading {
      color: transparent !important;
    }
    &:hover {
      box-shadow: 0px 10px 20px rgbaColor($theme-color-secondary, 0.2);
    }
  }

  &._cancel {
    background: transparent;
    border: 1px solid #cacad4;
    color: $theme-heading-color;
  }

  &._red {
    border: 1px solid #fd1941;
    background: transparent;
    color: #fd1941;
  }

  &._disabled {
    cursor: not-allowed;
    background: #efeff9;
    pointer-events: none;
    color: $theme-svg-color;
    border-color: transparent;
  }
}
